import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Box, Heading, Paragraph, Grid, ResponsiveContext } from 'grommet';

import SmartLink from '../SmartLink';
import ImgixImage from '../ImgixImage';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';
import { getProductPath, findImageOfType } from '../../lib/product';

import { SIMILAR_PRODUCT_CLICKED } from '../../lib/analytics/segmentActions';

const RelatedProducts = ({
  item,
  relatedProducts,
  content,
  listItemComponent,
  itemImageType = 'collection',
  collection,
  metaContainerProps,
  listContainerProps,
  isPaint = false,
  title,
  description,
  ...rest
}) => {
  const ListItemComponent = listItemComponent;
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';
  const altLayout = size === 'medium' || size === 'medsmall';
  const image = findImageOfType(itemImageType, item);
  const largeFontStyle = useAdjustedHeadingSize(1);

  return (
    <Box
      direction={'row-responsive'}
      border={{ side: 'bottom', size: 'small', color: 'black' }}
      {...rest}
    >
      <Box
        basis={isMobile ? 'auto' : '490px'}
        pad={isMobile ? 0 : 'large'}
        border={
          isMobile ? null : { side: 'right', size: 'small', color: 'black' }
        }
        {...metaContainerProps}
      >
        <Box fill>
          <Box
            fill
            pad={
              isMobile ? { vertical: 'medium', horizontal: 'large' } : 'none'
            }
            border={
              isMobile
                ? { side: 'bottom', size: 'small', color: 'black' }
                : undefined
            }
            justify="around"
          >
            <Heading
              textAlign={isPaint ? (isMobile ? 'start' : 'center') : 'start'}
              margin="none"
              level={2}
              style={largeFontStyle}
            >
              {get('title', content) || title || 'Similar'}
            </Heading>
            {description && !isMobile && <Paragraph>{description}</Paragraph>}
          </Box>
          {!isMobile && item && (
            <Box margin={{ top: 'medium' }} gap="small">
              {itemImageType && image && (
                <Box>
                  <ImgixImage
                    src={get('url', image)}
                    options={{ w: 180, h: 180 }}
                    fit="contain"
                    alignSelf="center"
                    width={180}
                  />
                </Box>
              )}
              <Heading level={5} textAlign="center" fill>
                {item.title}
              </Heading>
            </Box>
          )}
        </Box>
        {get('body', content) && (
          <Box pad={isMobile ? 'large' : undefined}>
            <Paragraph
              textAlign={isPaint ? (isMobile ? 'start' : 'center') : 'start'}
            >
              {get('body', content)}
            </Paragraph>
          </Box>
        )}
      </Box>

      <Box
        flex={true}
        pad="medium"
        overflow={altLayout ? 'hidden' : { horizontal: 'scroll' }}
        {...listContainerProps}
      >
        {altLayout ? (
          <Grid fill gap="medium" columns={{ count: 2, size: 'auto' }}>
            {relatedProducts.map((x) => {
              return (
                <SmartLink
                  key={x.slug || x.uuid || x._uid}
                  to={getProductPath(x, collection)}
                  style={{
                    textDecoration: 'none',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <ListItemComponent {...x} flex={false} />
                </SmartLink>
              );
            })}
          </Grid>
        ) : (
          <Box
            direction="row"
            gap="medium"
            pad="medium"
            wrap={false}
            fill
            justify={isMobile ? 'start' : 'center'}
            className="inner-scroller"
          >
            {relatedProducts.map((x) => (
              <Box
                flex={isMobile ? false : true}
                key={x.slug || x.uuid || x._uid}
                justify="center"
              >
                <SmartLink
                  title={get('title', x)}
                  to={getProductPath(x, collection)}
                  style={{
                    textDecoration: 'none',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                  }}
                  track={{
                    event: SIMILAR_PRODUCT_CLICKED,
                    properties: { product: x },
                  }}
                >
                  <ListItemComponent {...x} />
                </SmartLink>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

RelatedProducts.propTypes = {
  item: PropTypes.object,
  relatedProducts: PropTypes.array.isRequired,
  isPaint: PropTypes.bool,
  itemImageType: PropTypes.string,
  collection: PropTypes.string,
  content: PropTypes.object.isRequired,
  listItemComponent: PropTypes.elementType,
  metaContainerProps: PropTypes.object,
  listContainerProps: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default memo(RelatedProducts);
